import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import wallet from "../../../../assets/images/wallet.png";
import eth from "../../../../assets/images/eth.png";
import uniswap from "../../../../assets/images/uniswap.png";
import master from "../../../../assets/images/master.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  width: 100%;
  padding: 0px 0 !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  background-color: #00000000;
  padding: 40px 5px !important;
  backdrop-filter: blur(15px);
`;
export const CustomCardGrid = styled(CustomGrid)`
  background-color: ${(props) => (props.bgc ? props.bgc : "#0c0c0c")};
  padding: 20px;
`;
const HowToBuy = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="howtobuy">
        <InnerContinerWraper>
          <CustomGrid container g="20px">
            <CustomGrid item xs={12}>
              <SectionHeading>hOW TO BUY</SectionHeading>
            </CustomGrid>
            <CustomCardGrid item xs={12} ai="start" jc="start" g="20px">
              <DataImage mw="75px" src={wallet} />
              <CustomGrid item xs={12} fd="column" ai="start">
                <Paragraph
                  color="#7CB234"
                  fontFamily="Turtles"
                  sx={{ fontSize: { xs: "18px", sm: "20px" } }}
                >
                  CREATE WALLET
                </Paragraph>
                <Paragraph>
                  Begin by setting up a secure wallet such as Metamask for the
                  safe storage and control of your digital assets.
                </Paragraph>
              </CustomGrid>
            </CustomCardGrid>
            <CustomCardGrid item xs={12} ai="start" jc="start" g="20px">
              <DataImage mw="75px" src={eth} />
              <CustomGrid item xs={12} fd="column" ai="start">
                <Paragraph
                  color="#7CB234"
                  fontFamily="Turtles"
                  sx={{ fontSize: { xs: "18px", sm: "20px" } }}
                >
                  GET SOME ETH
                </Paragraph>
                <Paragraph>
                  Obtain Ethereum through a reliable exchange and transfer it to
                  your Metamask wallet.
                </Paragraph>
              </CustomGrid>
            </CustomCardGrid>
            <CustomCardGrid item xs={12} ai="start" jc="start" g="20px">
              <DataImage mw="75px" src={uniswap} />
              <CustomGrid item xs={12} fd="column" ai="start">
                <Paragraph
                  color="#7CB234"
                  fontFamily="Turtles"
                  sx={{ fontSize: { xs: "18px", sm: "20px" } }}
                >
                  GO TO UNISWAP
                </Paragraph>
                <Paragraph>
                  Visit the Uniswap platform and connect your Metamask wallet to
                  explore available cryptocurrencies.
                </Paragraph>
              </CustomGrid>
            </CustomCardGrid>
            <CustomCardGrid item xs={12} ai="start" jc="start" g="20px">
              <DataImage mw="75px" src={master} />
              <CustomGrid item xs={12} fd="column" ai="start">
                <Paragraph
                  color="#7CB234"
                  fontFamily="Turtles"
                  sx={{ fontSize: { xs: "18px", sm: "20px" } }}
                >
                  SWAP ETH For $MASTER
                </Paragraph>
                <Paragraph>
                  Execute a trade on Uniswap, swapping your Ethereum for $MASTER
                  tokens by connecting and approving the transaction through
                  your Metamask wallet.
                </Paragraph>
              </CustomGrid>
            </CustomCardGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HowToBuy;
