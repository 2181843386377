import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import LockerMenu from "../../LockerMenu";
import heroBg from "../../../../assets/images/heroBg.png";
import heroImage from "../../../../assets/images/heroImage.png";
import cashes from "../../../../assets/images/cashes.png";
import {  CustomGrid, DataImage, SectionHeading, StrokeText } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  padding: 0 !important;
  background-color: #000;
  background-image: url(${heroBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px -150px 80px #000;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;
const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh);
  width: 100%;
  z-index: 1;
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "50%")};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,-50%)")};
  max-width: ${(props) => (props.mw ? props.mw : "43%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    top: ${(props) => (props.t599 ? props.t599 : props.t599)};
    left: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const HeroSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="home">
        <InnerContinerWraper>
          <LockerMenu />
          <CustomGrid container ai="end"
              sx={{minHeight:"calc(100dvh - 100px)"}}>
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={6}
              ai="end"
            >
              <AbsoluiteImage src={heroImage} />
              <div
                style={{
                  width: "100%",
                  padding: "10px",
                  display:"flex",
                  justifyContent:"space-between",
                  background: "#7CB234",
                  border: "3px solid #000",
                  boxShadow: "0px 5px 0px #212121",
                }}
              >
                <StrokeText fontFamily="MemesiqueR">CA:</StrokeText>
                <StrokeText fontFamily="MemesiqueR" textAlign="right">
                0xc30531650828889cc3ec967bdd4ac1a7890f4142
                </StrokeText>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
