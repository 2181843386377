import React from "react";
import { Container} from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, SectionHeading, StrokeText } from "../../../Styles/style";
import { CustomCardGrid } from "../S4-HowToBuy";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 50px 5px !important;
  z-index: 1;
  backdrop-filter: blur(15px);
  background-color: #00000000;
`;


const Tokenomics = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <InnerContinerWraper>
          <CustomCardGrid container g="20px" padding="40px 0 40px !important">
            <CustomGrid item xs={12}>
              <SectionHeading ta="center">TOKENOMICS</SectionHeading>
            </CustomGrid>

            <CustomCardGrid item fd="column" bgc="#000000" xs={10}>
              <StrokeText fs="30px" fs599="20px">
                cONTRACT ADDRESS
              </StrokeText>
              <StrokeText fs="30px" fs599="20px">
              0xc30531650828889cc3ec967bdd4ac1a7890f4142
              </StrokeText>
              <CustomGrid item xs={12} g="10px">
                <CustomButton href="https://app.uniswap.org/swap?outputCurrency=0xc30531650828889cc3ec967bdd4ac1a7890f4142&chain=ethereum" target="blank">BUY NOW</CustomButton>
                <CustomButton href="https://www.dextools.io/app/en/ether/pair-explorer/0x4c8cc2f37bb7d54bca97631e73c7079f70ef2c2e" target="blank" bgc="transparent" b="3px solid #fff">
                  VIEW CHART
                </CustomButton>
              </CustomGrid>
            </CustomCardGrid>
            <CustomCardGrid item fd="column" bgc="#000000" xs={12} sm={4.9}>
              <StrokeText ts="0" fs="50px" fs599="30px">
                TBA
              </StrokeText>
              <StrokeText ts="0" fs="30px" fs599="16px">
                Supply
              </StrokeText>
            </CustomCardGrid>
            <CustomCardGrid item fd="column" bgc="#000000" xs={12} sm={4.9}>
              <StrokeText
                ts="0"
                fs="50px"
                fs599="30px"
                c="#E18E2F"
                sx={{ textDecoration: "underline" }}
              >
                BURNT
              </StrokeText>
              <StrokeText ts="0" fs="30px" fs599="16px">
                Liquidity
              </StrokeText>
            </CustomCardGrid>
            <CustomCardGrid item fd="column" bgc="#000000" xs={12} sm={4.9}>
              <StrokeText ts="0" fs="50px" fs599="30px" c="#7CB234">
                0%
              </StrokeText>
              <StrokeText ts="0" fs="30px" fs599="16px">
                BUY TAX
              </StrokeText>
            </CustomCardGrid>
            <CustomCardGrid item fd="column" bgc="#000000" xs={12} sm={4.9}>
              <StrokeText ts="0" fs="50px" fs599="30px" c="#E12F3B">
                0%
              </StrokeText>
              <StrokeText ts="0" fs="30px" fs599="16px">
                sELL TAX
              </StrokeText>
            </CustomCardGrid>
          </CustomCardGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;
