import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import Community from "../../Components/Sections/S5-Community";
import Tokenomics from "../../Components/Sections/S3-Tokenomics";
import HowToBuy from "../../Components/Sections/S4-HowToBuy";
import videoBg from "../../../assets/images/Master BG_x264.mp4"
import styled from "@emotion/styled";
const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;

const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <VideoBK autoPlay loop muted>
          <source src={videoBg} type="video/mp4" />
        </VideoBK>
      <HeroSection />
      <AboutSection />
      <Tokenomics />
      <HowToBuy />
      <Community />
    </PageWrapper>
  );
};

export default MainPage;
