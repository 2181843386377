import { Typography } from "@mui/material";
import React from "react";

const Paragraph = (props) => {
  const { children } = props;
  return (
    <Typography
      color="#fff"
      fontFamily="PoppinsR"
      fontSize="16px"
      textAlign="left"
      padding="0px"
      margin="0px"
      
      sx={{ fontSize: { xs: "14px", sm: "16px" } }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Paragraph;
