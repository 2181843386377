import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import {
  CustomGrid,
  DataImage,
  SectionHeading,
  StrokeText,
} from "../../../Styles/style";
import coingeko from "../../../../assets/images/coingecko.png";
import coinmarketcap from "../../../../assets/images/coinmarketcap.png";
import Image1 from "../../../../assets/images/Image1.jpg";
import Image2 from "../../../../assets/images/Image2.jpg";
import Image3 from "../../../../assets/images/Image3.jpg";
import Image4 from "../../../../assets/images/Image4.jpg";
import Image5 from "../../../../assets/images/Image5.jpg";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  padding: 0px 0 !important;
  position: relative;
`;

const InnerContinerWraper = styled(Container)`
  background-color: #00000000;
  padding: 40px 5px 0px !important;
  backdrop-filter: blur(15px);
`;

const Community = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="howtobuy">
      <InnerContinerWraper>
        <CustomGrid container g="20px">
          <CustomGrid item xs={12}>
            <SectionHeading>Exchanges/partners</SectionHeading>
          </CustomGrid>
          <CustomGrid item xs={12}>
            <StrokeText fs="50px" fs599="30px">"Coming soon"</StrokeText>
          </CustomGrid>
          <CustomGrid item xs={12} g="20px">
            <DataImage mw="150px" src={coingeko} />
            <DataImage mw="150px" src={coinmarketcap} />
          </CustomGrid>
          <CustomGrid container item xs={12} spacing={2}>
            <CustomGrid item xs={12} sm={5}>
              <DataImage mw="98%" src={Image1} />
            </CustomGrid>
            <CustomGrid item xs={12} sm={7}>
              <DataImage mw="98%" src={Image2} />
            </CustomGrid>
            <CustomGrid item xs={12} sm={4}>
              <DataImage mw="98%" src={Image3} />
            </CustomGrid>
            <CustomGrid item xs={12} sm={8}>
              <DataImage mw="98%" src={Image4} />
            </CustomGrid>
            <CustomGrid item xs={12} sm={12}>
              <DataImage mw="98%" src={Image5} />
            </CustomGrid>
          </CustomGrid>
        </CustomGrid>
        <CustomGrid item xs={12} p="20px 0" jc="space-between">
          <Paragraph textAlign="center">
            Email: info@https://mastervitalik
          </Paragraph>
          <Paragraph textAlign="center">
            © 2024 Master Vitalik. All rights reserved.
          </Paragraph>
        </CustomGrid>
      </InnerContinerWraper>
    </ContinerWraper>
  );
};

export default Community;
