import React from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import styled from "@emotion/styled";

import Cash from "../../../../assets/images/cashes.png";
import Pump from "../../../../assets/images/aboutImage.png";
import { SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";

const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 1;
  backdrop-filter: blur(15px);
  background-color: #00000000;
  box-shadow: inset 0px 50px 80px #000;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #7cb234;
  color: #ffffff;
  border-radius: 0px;
  min-width: 280px;
  font-family: MemesiqueR;
  border-color: #000000;
  border-width: 3px;
  border-style: solid;
  filter: drop-shadow(0px 5px 0px #212121);

  font-size: 25px;
  -webkit-text-stroke: 1.5px;
  -webkit-text-stroke-color: #000;

  &:hover {
    background-color: #7cb234;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about" position="relative">
        <img src={Cash} alt="green money" className="moneygreen"></img>

        <InnerContinerWraper>
          <img src={Cash} alt="green money" className="moneygreen2"></img>

          <Grid container sx={{ marginTop: { xs: "60px", xl: "400px" } }}>
            <Grid container xs={12} md={7} padding="0 0 20px" columnSpacing={2}>
              <Grid item xs={12}>
                <SectionHeading ta="left" fs599="36px">
                  MASTER
                </SectionHeading>

                <SectionHeading ta="left" c="white" fs599="36px">
                Vitalik
                </SectionHeading>
              </Grid>

              <Grid item padding="30px 0 0">
                <Paragraph
                  fontFamily="PoppinsM"
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    paddingRight: { xs: "10px", md: "100px" },
                  }}
                >
                  In the vast expanse of blockchain, Master Vitalik, the
                  visionary behind Ethereum and leader of the Crypto Turtles,
                  emerged as a beacon of decentralized rebellion. Frustrated by
                  the allure of Solana and its misled sheep, he rallied his
                  turtles to defend the principles of decentralization. <br />
                  <br />
                  The battle between Ethereum and Solana symbolized a larger
                  ideological struggle, with Master Vitalik leading the charge
                  against centralization. Join the rebellion, stand with the
                  Father of Ethereum, and let the Crypto Turtles guide you
                  towards a decentralized future, breaking free from the
                  conformity of Solana's flock.
                </Paragraph>
              </Grid>

              <Grid item padding="30px 0 0">
                <StyledButton href="https://t.me/MasterERC20" target="blank">
                  JOIN TELEGRAM COMMUNITY
                </StyledButton>
              </Grid>
            </Grid>

            <Grid container xs={12} md={4}>
              <Grid item>
                <img src={Pump} alt="pump pump pump" className="Pump"></img>
              </Grid>
            </Grid>
          </Grid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
